<template>
  <center-screen>
    <v-form ref="form" v-model="valid" class="mt-3" @submit.prevent>
      <v-row>
        <v-col>
          <v-text-field
            v-model="model.name"
            :counter="128"
            :rules="rules.name"
            :label="$t('general.name')"
            prepend-icon="mdi-card-account-details"
            required
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-combobox
            v-model="selectedCountry"
            :items="countryCodes"
            item-text="name"
            :rule="rules.countryDialCode"
            dense
            prepend-icon="mdi-phone"
          >
            <template v-slot:item="{ item }">
              <img
                :src="
                  `https://flagcdn.com/16x12/${item.code.toLowerCase()}.png`
                "
                width="16"
                height="12"
                :alt="item.name"
              />
              <span class="px-1">{{ item.name }}</span>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="model.phone"
            :counter="9"
            :rules="rules.phone"
            :label="$t('general.phone')"
            required
            type="number"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <template v-slot:actions>
      <v-btn text color="primary" depressed @click="goToLogin">
        {{ $t("login.login") }}
      </v-btn>
      <v-spacer />
      <v-btn color="primary" tile depressed @click="tryRegister">
        {{ $t("login.register") }}
      </v-btn>
    </template>
  </center-screen>
</template>

<script>
import { mapActions } from "vuex";
import CenterScreen from "@/common/layouts/CenterScreen";

export default {
  name: "Register",
  components: { CenterScreen },
  data() {
    return {
      redirect: "/",
      valid: false,
      model: { name: "", phone: "" },
      selectedCountry: null,
      countryCodes: [
        {
          name: "Jordan",
          nameAr: "الأردن",
          dialCode: "+962",
          code: "JO",
        },
        {
          name: "Yemen",
          nameAr: "اليمن",
          dialCode: "+967",
          code: "YE",
        },
      ],
      rules: {
        name: [
          (v) => !!v || "Name is required",
          (v) => (!!v && v.length >= 4) || "Name must be at least 4 characters",
          (v) =>
            (!!v && v.length <= 64) ||
            "Must be less than or eaqual to 64 characters",
        ],
        phone: [
          (v) => !!v || "Phone number is required",
          (v) =>
            (!!v && v.length === 9) ||
            "Please enter 9 digits for the phone number",
          (v) => {
            const re = /^[+\-/0-9 ]*$/;
            return (
              re.test(String(v).toLowerCase()) ||
              "Please enter phone number in digits only"
            );
          },
        ],
        countryDialCode: [
          (v) =>
            !!v || "Pease select the country dia-code for the phone number",
        ],
      },
    };
  },
  methods: {
    ...mapActions("auth", ["register"]),

    tryRegister() {
      this.$refs.form.validate();

      setTimeout(() => {
        if (this.valid) {
          const phone = this.selectedCountry.dialCode + this.model.phone;
          const params = {
            name: this.model.name,
            phone,
            registrationType: "phone",
          };
          window.localStorage.setItem("registerPhone", phone);
          this.register(params).then((user) => {
            this.$router.push({
              name: "pin",
              query: params,
            });
          });
        }
      }, 300);
    },
    goToLogin() {
      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>
