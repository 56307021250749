import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('center-screen',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"text":"","color":"primary","depressed":""},on:{"click":_vm.goToLogin}},[_vm._v(" "+_vm._s(_vm.$t("login.login"))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","tile":"","depressed":""},on:{"click":_vm.tryRegister}},[_vm._v(" "+_vm._s(_vm.$t("login.register"))+" ")])]},proxy:true}])},[_c(VForm,{ref:"form",staticClass:"mt-3",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"counter":128,"rules":_vm.rules.name,"label":_vm.$t('general.name'),"prepend-icon":"mdi-card-account-details","required":"","dense":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VCombobox,{attrs:{"items":_vm.countryCodes,"item-text":"name","rule":_vm.rules.countryDialCode,"dense":"","prepend-icon":"mdi-phone"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":("https://flagcdn.com/16x12/" + (item.code.toLowerCase()) + ".png"),"width":"16","height":"12","alt":item.name}}),_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"8"}},[_c(VTextField,{attrs:{"counter":9,"rules":_vm.rules.phone,"label":_vm.$t('general.phone'),"required":"","type":"number","dense":""},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }